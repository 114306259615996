.App {
  text-align: center;
  max-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
}

.App__img {
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100vh;
}